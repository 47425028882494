import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import TradeleadStepper from './TradeleadStepper';
import ImageSlider from './ImageSlider';
import SearchBox from './SearchBox';
import { AuthContext } from '../../utils/AuthContext';

function Hero() {

    let { isTablet } = useContext(AuthContext);

    return (

        <div className='heroDiv'>

            <div className="heroContent">
                <div className="heroLeft">
                    {!isTablet && <SearchBox />}
                    <div className="heroContentMain">
                        <h1>EMPOWER YOUR <span>FAITH</span></h1>
                        <p>Explore a vast selection of religious products and services from trusted sellers.</p>
                        <div className="heroBtns">
                            <Link to="/products" className='primaryBtn'>Explore Products <i className="fa-solid fa-arrow-right"></i></Link>
                            <Link to="/services" className='primaryBtn2'>Find Services <i className="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="heroTradeleadFormDiv">
                        <TradeleadStepper />
                    </div>
                </div>
                <div className="heroRight">
                    <ImageSlider />
                </div>
            </div>
        </div>
    );
}

export default Hero;