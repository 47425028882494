import React from 'react'

function ProvisionsFilterTab({selectedType, setSelectedType}) {
    return (
        <div className='provisionsFilterTabDiv'>
            <button className={selectedType === "All" ? "selected" : ""} onClick={() => setSelectedType("All")}>All</button>
            <button className={selectedType === "Products" ? "selected" : ""} onClick={() => setSelectedType("Products")}>Products</button>
            <button className={selectedType === "Services" ? "selected" : ""} onClick={() => setSelectedType("Services")}>Services</button>
        </div>
    )
}

export default ProvisionsFilterTab