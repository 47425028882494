import React, { useContext, useEffect, useState } from 'react';
import { hostNameBack } from '../../utils/constants';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import Dropdown from "../../components/common/Dropdown";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { BsStripe } from 'react-icons/bs';
import { AuthContext } from '../../utils/AuthContext';
import { calculateTax } from '../../utils/utilFuncs';
import { GoStopwatch } from "react-icons/go";

function Boosts() {

    const [sellerServices, setSellerServices] = React.useState([]);
    const [boostCart, setBoostCart] = React.useState([]);
    const [sellerProducts, setSellerProducts] = React.useState([]);
    const [selectedPrices, setSelectedPrices] = React.useState({});
    const [showCheckoutModel, setShowCheckoutModel] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState("stripe");
    const [tax, setTax] = useState(0);
    const { user } = useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();
    const token = localStorage.getItem('token');


    React.useEffect(() => {
        axios.get(`${hostNameBack}/api/v1/services/seller/myServices/all/`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    const allServices = response.data.allServices;
                    const boosted = allServices.filter(p => p.status.includes("boosted"));
                    const nonBoosted = allServices.filter(p => !p.status.includes("boosted"));
                    setSellerServices([...boosted, ...nonBoosted]);
                }
            })
            .catch(e => {
                console.log(e);
                enqueueSnackbar(e.response?.data?.error || "Something went wrong", { variant: 'error' });
            });

        axios.get(`${hostNameBack}/api/v1/products/seller/myProducts/all/`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    const allProducts = response.data.allProducts;
                    const boosted = allProducts.filter(p => p.status.includes("boosted"));
                    const nonBoosted = allProducts.filter(p => !p.status.includes("boosted"));
                    setSellerProducts([...boosted, ...nonBoosted]);
                }
            })
            .catch(e => {
                console.log(e);
                enqueueSnackbar("Something went wrong", { variant: 'error' });
            });
    }, [token]);

    
    useEffect(() => {
        if (localStorage.getItem('boostPurchased')) {
            enqueueSnackbar("Boost purchase successful!", { variant: 'success' });
            localStorage.removeItem('boostPurchased');
        }
    }, []);


    const handleBoostClick = (item, type) => {
        const isAlreadyInCart = boostCart.some(cartItem => cartItem.id === item._id);
        const selectedPrice = selectedPrices[item._id] || 2;
        handlePriceSelect(item._id, selectedPrices[item._id] || 2);

        if (isAlreadyInCart) {
            setBoostCart(boostCart.filter(cartItem => cartItem.id !== item._id));
        } else {
            setBoostCart([...boostCart, { id: item._id, type, price: selectedPrice }]);
        }
    };


    const calculateRemainingBoostHours = (product) => {
        const expirationDate = new Date(product.boostExpiryDate);
        const remainingTime = (expirationDate - Date.now()) / (1000 * 60 * 60);
        return Math.max(0, Math.floor(remainingTime));
    };


    const handlePriceSelect = (itemId, price) => {
        setSelectedPrices(prevPrices => ({ ...prevPrices, [itemId]: price }));
    };


    const calculateTotals = () => {
        const postsTotal = boostCart.filter(item => item.type === "service").reduce((acc, item) => acc + item.price, 0);
        const productsTotal = boostCart.filter(item => item.type === "product").reduce((acc, item) => acc + item.price, 0);
        return { postsTotal, productsTotal, total: postsTotal + productsTotal };
    };


    const boostServicesElems = sellerServices?.length > 0 ? sellerServices.map((item, index) => {
        const cartItem = boostCart.find(cartItem => cartItem.id === item._id);
        const selectedPrice = selectedPrices[item._id] || 2;

        return (
            <div className="item" key={index}>
                <div className="aboutItem">
                    <img src={`${item?.serviceImages[0]}`} alt="Error" />
                    <p className='singleLineText'>{item?.title}</p>
                </div>
                {item.status.includes("boosted") ? <div className='timeRemaining'>
                    <GoStopwatch /> <span>Boost Ending in <span className='hours'>{calculateRemainingBoostHours(item)}h</span></span>
                </div> : <div className="itemActions">
                    <Dropdown
                        options={["$2 - 2 Days", "$5 - 7 Days", "$8 - 15 Days"]}
                        selected={`$${selectedPrice} - ${selectedPrice === 2 ? "2 Days" : selectedPrice === 5 ? "7 Days" : "15 Days"}`}
                        onSelect={(value) => handlePriceSelect(item._id, parseInt(value.match(/\$(\d+)/)[1]))}
                        isDisable={cartItem}
                    />
                    <button className={`secondaryBtn ${cartItem ? "added" : ""}`} onClick={() => handleBoostClick(item, "service")}>
                        {cartItem ? "Remove" : "Boost"}
                    </button>
                </div>}
            </div>
        );
    }) : <div>No posting to show here!</div>;


    const boostProductsElems = sellerProducts?.length > 0 ? sellerProducts.map((item, index) => {
        const cartItem = boostCart.find(cartItem => cartItem.id === item._id);
        const selectedPrice = selectedPrices[item._id] || 2;

        return (
            <div className="item" key={index}>
                <div className="aboutItem">
                    <img src={`${item?.productImages[0]}`} alt="Error" />
                    <p className='singleLineText'>{item?.title}</p>
                </div>
                {item.status.includes("boosted") ? <div className='timeRemaining'>
                    <GoStopwatch /> <span>Boost Ending in <span className='hours'>{calculateRemainingBoostHours(item)}h</span></span>
                </div>
                    :
                    <div className="itemActions">
                        <Dropdown
                            options={["$2 - 2 Days", "$5 - 7 Days", "$8 - 15 Days"]}
                            selected={`$${selectedPrice} - ${selectedPrice === 2 ? "2 Days" : selectedPrice === 5 ? "7 Days" : "15 Days"}`}
                            onSelect={(value) => handlePriceSelect(item._id, parseInt(value.match(/\$(\d+)/)[1]))}
                            isDisable={cartItem}
                        />
                        <button className={`secondaryBtn ${cartItem ? "added" : ""}`} onClick={() => handleBoostClick(item, "product")}>
                            {cartItem ? "Remove" : "Boost"}
                        </button>
                    </div>}
            </div>
        );
    }) : <div>No product to show here!</div>;


    const { postsTotal, productsTotal, total } = calculateTotals();


    useEffect(() => {
        setTax(calculateTax(total, user.sellerId?.country, user.sellerId?.state))
    }, [total, user])


    const handleBoostPurchase = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!stripe || !elements)
            return;

        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading(false);
            return;
        }

        try {
            const boostData = boostCart.map(item => ({
                itemId: item.id,
                type: item.type,
                price: item.price,
                boostDays: selectedPrices[item.id] === 2 ? 2 : selectedPrices[item.id] === 5 ? 7 : 15
            }));

            const { data } = await axios.post(`${hostNameBack}/api/v1/sellers/boost/purchase/${user?.sellerId?._id}`, { boostData, tax }, { headers: { Authorization: `Bearer ${token}` } });

            const clientSecret = data.clientSecret;

            const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id
            });

            if (stripeError) {
                enqueueSnackbar(stripeError.message, { variant: 'error' });
                setLoading(false);
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                setLoading(false);
                setShowCheckoutModel(false);
                setBoostCart([]);
                localStorage.setItem("boostPurchased", 'true');
                window.location.reload();
            }

        } catch (err) {
            enqueueSnackbar("Something went wrong!", { variant: 'error' });
            setLoading(false);
        }
    };

    return (
        <div className='boostDiv'>
            <section className='section'>
                <div className="boostContent">

                    <div className="boostItems">
                        <div className="itemsDiv">
                            <h2 className="secondaryHeading">Services</h2>
                            <div className="boostServices items">{boostServicesElems}</div>
                        </div>
                        <div className="horizontalLine"></div>
                        <div className="itemsDiv">
                            <h2 className="secondaryHeading">Products</h2>
                            <div className="boostProducts items">{boostProductsElems}</div>
                        </div>
                    </div>

                    <div className="boostCart">
                        <h2 className="secondaryHeading">Order <span>Summary</span></h2>
                        <div className="checkoutItemsList">
                            {boostCart?.length > 0 ? boostCart.map((item, index) => (
                                <div className="row" key={index}>
                                    <span>01 x {item.type === "service" ? "Post" : "Product"}</span>
                                    <span className='price'>${item.price}</span>
                                </div>
                            )) : "Nothing to show here..."}
                        </div>
                        <div className="horizontalLine"></div>
                        <div className='checkoutItemsList'>
                            <div className="row"><span>Posts Total</span> <span className='price'>${postsTotal}</span></div>
                            <div className="row"><span>Products Total</span> <span className='price'>${productsTotal}</span></div>
                        </div>
                        <div className="horizontalLine"></div>
                        <div className="row"><span>Total</span> <span className='totalPrice'>${total}</span></div>
                        <button className="primaryBtn" onClick={() => setShowCheckoutModel(true)} disabled={total === 0}>Proceed to Checkout</button>
                    </div>

                </div>
            </section>

            {showCheckoutModel && (
                <div className="popupDiv boostCheckoutModelDiv">
                    <div className="popupContent">
                        <div className="form">

                            <h2 className="secondaryHeading"><span>Payment</span> Method</h2>

                            <div className="horizontalLine"></div>

                            <div className='row'><span>Total</span><strong>${total}</strong></div>
                            <div className='row'><span>Tax</span><strong>${tax}</strong></div>
                            <div className='row'><span>Total</span><strong>${total + tax}</strong></div>

                            <div className="horizontalLine"></div>

                            <div className="paymentOptions">
                                <div className={`stripe paymentOption ${paymentMethod === 'stripe' ? 'selected' : ''}`} onClick={() => setPaymentMethod('stripe')}><BsStripe className='icon' /><div>Stripe</div></div>
                            </div>

                            {paymentMethod === "stripe" && <form className='form'>

                                <div className="inputDiv">
                                    <label>Card Number</label>
                                    <CardNumberElement className="inputField" />
                                </div>

                                <div className="inputDiv">

                                    <div className="inputInnerDiv">
                                        <label>Expiry Date</label>
                                        <CardExpiryElement className="inputField" />
                                    </div>

                                    <div className="inputInnerDiv">
                                        <label>CVC</label>
                                        <CardCvcElement className="inputField" />
                                    </div>

                                </div>

                            </form>}

                        </div>
                        <div className="buttonsDiv">
                            <button className="primaryBtn" type="submit" onClick={handleBoostPurchase} disabled={loading}>Pay ${total + tax}</button>
                            <button className="secondaryBtn" onClick={() => setShowCheckoutModel(false)}>Close</button>
                        </div>
                        <div className="popupCloseBtn">
                            <IoIosCloseCircleOutline className="icon" onClick={() => setShowCheckoutModel(false)} />
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}

export default Boosts;
