
import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { hostNameBack } from '../../utils/constants';
import { Link } from 'react-router-dom';

function ResetPasswordRequest() {
    const [email, setEmail] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    const requestPasswordReset = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${hostNameBack}/api/v1/auth/resetPasswordRequest`, { email });
            if (response.data.success)
                enqueueSnackbar("Password reset link sent to your email", { variant: "success" });
            else
                enqueueSnackbar("Something went wrong", { variant: "success" });
        } catch (e) {
            if (e.response?.data?.error)
                enqueueSnackbar(e.response?.data?.error, { variant: "error" });
            else {
                console.log(e);
                enqueueSnackbar('Error Sending Email', { variant: "error" });
            }
        }
    };

    return (
        <div className='resetPasswordRequestDiv'>

            <Link to="/" className="faithzyLogoDiv">
                <img src="./assets/images/logo.svg" className='faithzyLogo' alt="Error" />
            </Link>

            <div className="resetPasswordBanner">
                <img src="./assets/images/reset.svg" alt="Error" />
            </div>

            <section className="section">

                <form onSubmit={requestPasswordReset} className='form'>
                    <h1 className='primaryHeading'>Reset <span>Password</span></h1>
                    <div className='inputDiv'>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder='Enter your email'
                            value={email}
                            className='inputField'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <input type="submit" value="Send Password Reset Link" className='primaryBtn' />
                    </div>
                </form>

            </section>
        </div>
    );
}

export default ResetPasswordRequest;
