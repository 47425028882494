import React, { useContext } from 'react'

import SampleProvisions from '../../components/buyer/SampleProvisions';
import { AuthContext } from "../../utils/AuthContext";

function Provisions({ selectedType }) {

  const { isLogin } = useContext(AuthContext);

  return (
    <div className="homeProvisionsDiv">
      <section className='section'>

        <div className="homeProvisionsContainer">
          {(selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="top" selectedType="Products" />}
          {(selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="top" selectedType="Services" />}
        </div>

        <div className="homeProvisionsContainer">
          {isLogin && (selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="recents" selectedType="Products" />}
          {isLogin && (selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="recents" selectedType="Services" />}
        </div>

        <div className="homeProvisionsContainer">
          {(selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="discounted" selectedType="Products" />}
          {(selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="discounted" selectedType="Services" />}
        </div>

      </section>
    </div>
  )
}

export default Provisions